import { Injectable } from '@angular/core';
import { GlobalDataService } from './globaldata.service';
import { PostService } from './PostService';
@Injectable()
export class AllfunctionService {

  Allfunction: string;
  inputtNFUrl: string;
  apinew: string;
  login: string;
  constructor(private gd: GlobalDataService, private ps: PostService) {
    this.Allfunction = this.gd.shareObj['AllAPI'];
    this.inputtNFUrl = "https://pwcapi.pwcmall.in.th/index.php/InputData/"
    this.apinew = "https://pwcapinew.pwcmall.in.th/index.php"
    this.login = "https://laravel-api.pwcmall.in.th/api/"
    // this.apinew = "https://laravel-api.pwcmall.in.th/api/"
  }

  getCarInfo() {
    return this.ps.post(this.Allfunction + "getCarInfo", {})
  }
  getOilInfo(req: any) {
    return this.ps.post(this.Allfunction + 'getOilInfo', req);
  }
  getFueltype() {
    return this.ps.post(this.Allfunction + 'getFueltype', {});
  }
  addItemsinsurance(req: any) {
    return this.ps.post(this.Allfunction + 'addItemsinsurance', req);
  }
  addItems(req: any) {
    return this.ps.post(this.Allfunction + 'addItems', req);
  }
  addItemmaintenance(req: any) {
    return this.ps.post(this.Allfunction + 'addItemmaintenance', req);
  }
  getData() {
    return this.ps.post(this.Allfunction + 'getData', {});
  }
  saveImageReuqest(req: any) {
    return this.ps.post(this.inputtNFUrl + "saveImageFileForTransport", req);
  }
  addDistance(req) {
    return this.ps.post(this.Allfunction + 'addDistance', req);
  }
  getDistance() {
    return this.ps.post(this.Allfunction + 'getDatadistance', {});
  }
  rundocNumber(req: any) {
    return this.ps.post(this.apinew + 'Offline/rundocNumberTransport', req)
  }
  getRundocNewProbiz(req){
    return this.ps.get( 'input/getRundocNewProbiz2/sequence='+req.sequence);
  }
  getLogin(req: any) {
    return this.ps.post(this.login + "settings/getLogin", req);
  }
}
