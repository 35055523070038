/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PostObject } from './PostObject';
import { GlobalDataService } from './globaldata.service';

@Injectable()
export class PostService {
  constructor(private http: HttpClient, private gd: GlobalDataService) {}
  get(url: string) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let result = this.http
      .get(url, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
    return result;
  }

  post(url: string, req: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let result = this.http
      .post(url, req, {
        headers: headers,
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);

    return result;
  }

  postSubSubscribe(url: string, req: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let result = this.http.post(url, req, {
      headers: headers,
      withCredentials: true,
    });

    return result;
  }

  private extractData(res) {
    let body = res;
    return body || [];
  }
  private handleError(error: any) {
    try {
      let errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
      if (errMsg == 'Server error') {
        alert('Server error!');
      }
      console.error(errMsg); // log to console instead
      //create error result object
      let result = new PostObject();
      result.msg = errMsg.status;
      result.resultCode = errMsg.status;
      result.detail = errMsg.statusText;
      return result;
    } catch (e) {
      console.log(error);
    }
    let result = new PostObject();
    result.msg = 'Error';
    result.resultCode = '0010';
    result.detail = 'No detail';
    return result;
  }
}
