import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'distance',
    pathMatch: 'full',
  },
  {
    path: 'folder',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'transport',
    loadChildren: () =>
      import('./transport/transport.module').then((m) => m.TransportPageModule),
  },
  {
    path: 'privacypolicy',
    loadChildren: () =>
      import('./insurance/insurance.module').then((m) => m.InsurancePageModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./maintenance/maintenance.module').then(
        (m) => m.MaintenancePageModule
      ),
  },
  {
    path: 'additem',
    loadChildren: () =>
      import('./additem/additem.module').then((m) => m.AdditemPageModule),
  },
  {
    path: 'carlist',
    loadChildren: () =>
      import('./carlist/carlist.module').then((m) => m.CarlistPageModule),
  },
  {
    path: 'addinsurance',
    loadChildren: () =>
      import('./addinsurance/addinsurance.module').then(
        (m) => m.AddinsurancePageModule
      ),
  },
  {
    path: 'addmaintenance',
    loadChildren: () =>
      import('./addmaintenance/addmaintenance.module').then(
        (m) => m.AddmaintenancePageModule
      ),
  },
  {
    path: 'distance',
    loadChildren: () =>
      import('./distance/distance.module').then((m) => m.DistancePageModule),
  },
  {
    path: 'adddistance',
    loadChildren: () =>
      import('./adddistance/adddistance.module').then(
        (m) => m.AdddistancePageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
