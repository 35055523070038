import { Injectable, EventEmitter, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';


//import { CookieService } from 'ngx-cookie';



interface ShareObj {
  [id: string]: any;
}

@Injectable()
export class GlobalDataService {
  Contacts: any[] = [];
  Typefuel:any[]=[];
  DataOil:any []= [];
  Datacar:any[] = [];
  DataAll: any[] = [];
  Fuel:any[]=[];
  Dataforcar:any[]=[];
  dataDistance:any[]=[];
  shareObj: ShareObj = {};
  @Output() userChangeEvent: EventEmitter<string> = new EventEmitter(true);
  @Output() selectTemplateChangeEvent: EventEmitter<any> = new EventEmitter(true);
  @Output() productChangeEvent: EventEmitter<string> = new EventEmitter(true);
  @Output() carChangeEvent: EventEmitter<string> = new EventEmitter(true);
  @Output() saveTemplateChangeEvent: EventEmitter<any> = new EventEmitter(true);
  @Output() test: EventEmitter<string> = new EventEmitter(true);

  keywords: any[] = [];
  Allfunction:string
  img:string
  imglogo:string;
  login_data: any = new Object()
  login_: any = new Object();
  ja:any
  constructor( private datePipe: DatePipe,private router: Router) {
    //this.shareObj['apiNew'] = "http://127.0.0.1/pwcAPINew/";
    this.img = "https://probiz.pwcmall.in.th/static/db/pwc_transport/files/"
    this.imglogo = "https://probiz.pwcmall.in.th/index.php/static/db/master/files/"
    this.shareObj['TransportAPI'] = "https://api-transport.pwcmall.in.th/index.php/";
    this.shareObj['AllAPI'] = this.shareObj['TransportAPI'] + "AllFunctions/";

    this.getDataUsername();
    this.checkUrl();
  }
  checkUrl() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // console.log(event.url);
        if (this.checkIsLogin() == false && event.url != '/transport') {
          this.logout();
        }
        return event.url;
      }
    })
  }
  checkIsLogin() {
    if (this.getStorage("login_data") && this.getStorage("login_data") != null) {
      return true;
    }
    return false;
  }
  numberWithCommas(x: number) {
    if (x == null) {
      return 0.00;
    }
    let tmpNumber = Number(x).toFixed(2);
    let stmpNumber = tmpNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return stmpNumber.replace(".00", "");
  }
  datePipeFormat2(date: any) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }
  datetimePipeFormat(date: any) {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }
  getDataUsername() {
    this.login_data = Object.assign(this.login_, this.getStorageArray('login_data'));
    console.log(this.login_data);
    let is_login_time = new Date().getDate();
    let login_time = is_login_time.toString();
    if (login_time != localStorage.getItem('login_time')) {
      this.logout();
    }
  }
  getStorageArray(value: any) {
    let data: any = this.getStorage(value);
    try {
      return JSON.parse(data);
    } catch (err) {
      console.warn(err);
      return new Object();
    }
  }
  getStorage(value: any) {
    return localStorage.getItem(value);
  }
  logout() {
    localStorage.clear();
    this.redirect("/transport");
  }
  redirect(url: string) {
    this.router.navigateByUrl(url);
  }
  setStorage(value: any, data: any) {
    localStorage.setItem(value, data);
  }
  countArray(arr: any[]) {
    try {
      return arr.length;
    } catch (e) {
      return 0
    }
  }
}
