import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalDataService } from './service/globaldata.service';
import { PostService } from './service/PostService';
import { HttpClientModule } from '@angular/common/http'
import { AllfunctionService } from './service/allfunction.service';
import { AlertService } from './service/alert.service';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common'




// import { Google} from '@capacitor/google-maps'






@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [CommonModule,BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,ReactiveFormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },GlobalDataService,PostService,AllfunctionService,AlertService,FormBuilder,Geolocation,NativeGeocoder,ImagePicker,Camera,File,Crop,DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
