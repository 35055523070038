import { Component } from '@angular/core';
import { AllfunctionService } from './service/allfunction.service';
import { GlobalDataService } from './service/globaldata.service';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as _ from 'underscore';

interface data {
  id: number;
  first: string;
  last: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'บันทึกค่าน้ำมัน', url: '/transport', icon: 'car-sport' },
    { title: 'บันทึกระยะทาง', url: '/distance', icon: 'document' },
    { title: 'Privacy Policy', url: '/privacypolicy', icon: 'document' },
    // { title: 'การซ่อมบำรุง', url: '/maintenance', icon: 'settings' },
  ];
  source: any[];
  target: any[];
  source1: any[];
  target1: any[];
  datas: any[] = [];
  date = new Date();

  constructor(
    public gd: GlobalDataService,
    private allfunction: AllfunctionService,
    public router: Router
  ) {}
  ngOnInit() {
    this.getData();
    console.log(this.date);
  }
  getData() {
    let req: any = new Object();
    let car_id = this.gd.Datacar;
    req.car_id = car_id;
    this.allfunction.getOilInfo(req).then((Res) => {
      this.gd.Dataforcar = Res;
      console.log(this.gd.Dataforcar);
    });
    this.allfunction.getFueltype().then((resData) => {
      this.gd.Typefuel = resData;
      console.log(this.gd.Typefuel);
    });
  }
  add: boolean = false;
  addinfo() {
    this.router.navigate(['additem']);
    this.add = true;
  }
  addinsurance() {
    this.router.navigate(['addinsurance']);
  }
  addmaintenance() {
    this.router.navigate(['addmaintenance']);
  }
  adddistance() {
    this.router.navigate(['adddistance']);
  }
}
