import { Injectable } from '@angular/core';
import { GlobalDataService } from './globaldata.service';
import { ToastController } from '@ionic/angular';
@Injectable()
export class AlertService {

    constructor(private gd: GlobalDataService, private alert: ToastController) {

    }

    async openToast(message,color){
      const toast = await this.alert.create({
        message: message,
        duration: 2000,
        color: color,
        position: "top"
      });
      toast.present();
    }
}
